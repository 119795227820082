<template>
  <div v-if='!loading' class='body'>
    <Nav></Nav>
    <div class="banner" style="margin-bottom:12px">
        <swiper ref="bannerSwiper" :options="bannerSwiperOptions" v-if="pageData.bannerList && pageData.bannerList.length">
          <swiper-slide v-for="img in pageData.bannerList" :key="img.id">
            <base-img class="banner-img" :src="img.url"></base-img>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    <div class='page-body page-content'>
      <!-- 联系我们 -->
      <div class="about-us-box">
        <div>
          <div class="map-box" id="map">
            <a-button class='reload-map' icon='caret-left' type='primary' @click='mapCenter=[119.066608, 36.747287];zoom=15;'>返回</a-button>
            <el-amap v-if="showMap" vid="amapDemo" :plugin="plugin" :resizeEnable="true" :zoom="mapZoom" :center="mapCenter">
              <el-amap-marker :events="{
                click: () => {
                  openWindow()
                },
              }" :position="mapCenter"></el-amap-marker>
              <el-amap-info-window :events="{
                close: () => {
                  closeWindow()
                },
              }" :visible="showWindow" :position="mapCenter" :content="content">
              </el-amap-info-window>
            </el-amap>
          </div>
          <div class='flex-j-b card'>
            <div class='form'>
              <div class="common-title-box">
                <div class="common-title">联系我们</div>
              </div>
              <div class="form-box">
                <div style='margin-right: 20px;'>
                  <input type="text" v-model="formName"  class="input input-name" placeholder="姓名" />
                  <input type="text" v-model="formPhone" class="input input-phone" maxlength="11"  placeholder="联系电话" />
                  <input type="text" v-model="formEmail" class="input input-emial" maxlength="32" placeholder="邮箱" />
                </div>
                <div>
                  <textarea v-model="formRemark" class="textarea input-remark" maxlength="200"  placeholder="留言"></textarea>
                </div>
              </div>
              <div class="form-btn" @click='submitMsg'>提交</div>
            </div>
            <div class='contact-type'>
              <div class="common-title-box">
                <div class="common-title">联系方式</div>
              </div>
              <div class='contact-box'>
                <div class='row'>
                  <icon-svg class="icon " icon-class="phone-icon" />
                  <a href='tel:0536-8915813'>0536-8915813</a>
                </div>
                <div class='row'>
                  <icon-svg class="icon " icon-class="email-icon" />
                  <a href='mailto:wfqingxin@126.com'>wfqingxin@126.com</a>
                </div>
                <div class='row'>
                  <icon-svg class="icon " icon-class="address-icon" />
                  <div>潍坊市潍城区玄武街西首三里庄路往西一百米路北</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
  <page-loading v-else></page-loading>
</template>

<script>
import Nav from '@/components/pageComponents/Nav'
import Footer from '@/components/pageComponents/Footer'
import { design, common } from '@/api'
export default {
  name: 'aboutUs',
  components: {
    Nav,
    Footer
  },
  data() {
    const self = this
    return {
      formName: '',
      formPhone: '',
      formEmail: '',
      formRemark: '',
      loading: true,
      editStatus: false,
      pageData: {},
      bannerSwiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        effect: 'fade',
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        loop: true,
      },
      mapZoom: 15,
      mapCenter: [119.066608, 36.747287],
      content: `<div class='info-window'>
                  <div class='info-window-name'>青欣建设集团</div>
                  <div class='link info-window-phone flex-row'>电话：<a href='tel:0536-8915813'>0536-8915813</a></div>
                  <div class='link info-window-email flex-row'>邮箱：<a href='mailto:wfqingxin@126.com'>wfqingxin@126.com</a></div>
                  <div class='info-window-addr'>地址：潍坊市潍城区玄武街西首三里庄路往西一百米路北</div>
                </div>`,
      plugin: [
        {
          pName: 'Geolocation',
          enableHighAccuracy: true,
          events: {
            init(o) {
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.content = ` <div class='info-window'>
                  <div class='info-window-name'>青欣建设集团</div>
                  <div class='link info-window-phone flex-row'>电话：<a href='tel:0536-8915813'>0536-8915813</a></div>
                  <div class='link info-window-email flex-row'>邮箱：<a href='mailto:wfqingxin@126.com'>wfqingxin@126.com</a></div>
                  <div class='info-window-addr'>地址：潍坊市潍城区玄武街西首三里庄路往西一百米路北</div>
                </div> `
                  self.mapCenter = [119.066608, 36.747287]
                  self.mapZoom = 15
                }
              })
            },
          },
        },
      ],
      showWindow: true,
      showMap: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.getDesignData()
    this.loading = false
    this.showMap = true
  },
  methods: {
    openWindow() {
      this.showWindow = true
    },
    closeWindow() {
      this.showWindow = false
    },
    async getDesignData() {
      const { data, msg, code } = await design.getDesign({ type: 'aboutUs' })

      if (code === '00000') {
        if (data) {
          this.designStorageId = data.designStorageId
          this.pageData = JSON.parse(data.designData)
        }
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    async submitMsg() {
      if(!this.formName) {
        return this.$message.error('请填写您的姓名')
      }
      if(this.formName.length>32) {
        return this.$message.error('请尝试简短一点的姓名')
      }
      if(!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.formPhone)) {
        return this.$message.error('请填写正确的手机号')
      }
      if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.formEmail)) {
        return this.$message.error('请填写正确的邮箱地址')
      }
      const {code,msg} = await common.submitForm({
        name: this.formName,
        phone: this.formPhone,
        email: this.formEmail,
        remark: this.formRemark
      })
      if(code === '00000') {
        this.$message.success('提交成功')
      } else {
        this.$message.error(msg||'提交失败')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.about-us-box {
  margin-bottom: 12px;
  justify-content: space-between;
}
#map {
  width: 100%;
  height: 400px;
  flex-shrink: 0;
  background: #eee;
  position: relative;
  margin-bottom: 12px;
}
.form-box {
  display: flex;
  & div {
    width: 420px;
  }
}
.form-box .input {
  display: block;
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  outline-color: #38b352;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  width: 100%;
  line-height: 24px;
}
.form-box .textarea {
  width: 100%;
  display: block;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 4px;
  resize: none;
  height: 142px;
  font-size: 14px;
  margin-bottom: 16px;
  box-sizing: border-box;
  outline-color: #38b352;
  width: 100%;
}
.form-btn {
  background-color: #38b352;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  width: 8em;
}
::v-deep .info-window {
  padding: 16px 12px;
  width: 380px;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  // border: 1px solid #999;
  // &:before,
  // &:after {
  //   position: absolute;
  //   content: '';
  //   border: 10px solid;
  // }
  // &:before {
  //   left: 177px;
  //   bottom: -21px;
  //   border-color: #999 transparent transparent transparent;
  // }
  // &:after {
  //   border-color: #fff transparent transparent transparent;
  //   left: 177px;
  //   bottom: -19px;
  // }
  .info-window-name {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 12px;
  }
  .info-window-phone {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }
  .info-window-email {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }

  .info-window-addr {
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
  }
  .link a:hover {
    color: #2a8ff3;
  }
  .info-window-btn {
    cursor: pointer;
    width: 4em;
    font-size: 14px;
    color: #fff;
    background-color: #2a8ff3;
    line-height: 32px;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    top: 8px;
    right: 14px;
  }
}

.reload-map {
  position: absolute;
  left: 8px;
  top: 20px;
  z-index: 1;
  cursor: pointer;
  background: #2a8ff3;
  border-color: #2a8ff3;
}
.form {
  width: 920;
  padding: 24px 0;
}
.contact-type {
  flex: 1;
  margin-left: 48px;
  padding: 24px 0;
}
.contact-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 144px;
  .row {
    display: flex;
    align-items: center;
    height: 36px;

    .icon {
      color: #38b352;
      font-size: 24px;
      margin-right: 16px;
    }
    a {
      display: block;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      &:hover {
        color: #2a8ff3;
      }
    }
    div {
      font-size: 14px;
      color: #333;
    }
  }
}
</style>